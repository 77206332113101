// 会员中心,所有name前缀带v
export default {
  parent: "member",
  routers: [
    // 无权访问
    {
      path: "limits",
      name: "vlimits",
      component: () => import("@/views/member/limitsView.vue"),
    },
    // 会员中心
    {
      path: "home",
      name: "vhome",
      component: () => import("@/views/member/homeView.vue"),
    },
    // 我的资料
    {
      path: "my",
      name: "vmy",
      component: () => import("@/views/member/myView.vue"),
    },
    // 资金流水
    {
      path: "fof",
      name: "vfof",
      component: () => import("@/views/member/fofView.vue"),
    },

    // 商品收藏
    {
      path: "collect",
      name: "vcollect",
      component: () => import("@/views/member/collectView.vue"),
    },
    // 系统消息
    {
      path: "msg",
      name: "vmsg",
      component: () => import("@/views/member/msgView.vue"),
    },
    // 系统消息-详情页
    {
      path: "msg/info",
      name: "vmsgInfo",
      component: () => import("@/views/member/msgInfo.vue"),
    },
    // 邀请奖励
    {
      path: "invite",
      name: "vinvite",
      component: () => import("@/views/member/inviteView.vue"),
    },
    // 平台资料
    {
      path: "news",
      name: "vnews",
      component: () => import("@/views/member/newsView.vue"),
    },
    // 平台资料-详情页
    {
      path: "news/info",
      name: "vnewsInfo",
      component: () => import("@/views/member/newsInfo.vue"),
    },
    // 我的客户
    {
      path: "customer",
      name: "vcustomer",
      component: () => import("@/views/member/customerView.vue"),
    },
    // 我的客户-详情页
    {
      path: "customer/info",
      name: "vcustomerInfo",
      component: () => import("@/views/member/customerInfo.vue"),
    },
    // 我的报价单
    {
      path: "ps/info",
      name: "vpsInfo",
      component: () => import("@/views/member/psInfo.vue"),
    },
    // 我的报价单-详情页
    {
      path: "ps",
      name: "vps",
      component: () => import("@/views/member/psView.vue"),
    },
    // 我的合同
    {
      path: "contract",
      name: "vcontract",
      component: () => import("@/views/member/contractView.vue"),
    },
    // 我的出货单
    {
      path: "so",
      name: "vso",
      component: () => import("@/views/member/soView.vue"),
    },
    // 业务数据统计
    {
      path: "data",
      name: "vdata",
      component: () => import("@/views/member/dataView.vue"),
    },
    // 项目地址管理
    {
      path: "pa",
      name: "vpa",
      component: () => import("@/views/member/paView.vue"),
    },
    // 计算工具
    {
      path: "ct",
      name: "vct",
      component: () => import("@/views/member/ctView.vue"),
    },
    // 计算工具-详情页
    {
      path: "ct/info",
      name: "vctInfo",
      component: () => import("@/views/member/ctInfo.vue"),
    },
    // 工程单据下载
    {
      path: "pdd",
      name: "vpdd",
      component: () => import("@/views/member/pddView.vue"),
    },
    // 意见反馈
    {
      path: "opinion",
      name: "vopinion",
      component: () => import("@/views/member/opinionView.vue"),
    },
    // 使用帮助
    {
      path: "help",
      name: "vhelp",
      component: () => import("@/views/member/helpView.vue"),
    },
    // 使用帮助-详情页
    {
      path: "help/info",
      name: "vhelpInfo",
      component: () => import("@/views/member/helpInfo.vue"),
    },
  ],
};
