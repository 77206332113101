import Vue from "vue";

// 全局引入lodash，参考文档：https://www.lodashjs.com/
import _ from "lodash";
Vue.prototype._ = _;

import App from "./App.vue";
import store from "./store";
import router from "./router";
import VueClipboard from "vue-clipboard2"; //复制
import Print from "vue-print-nb"; //打印

// 完整引入elementUI
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import { myMessage } from "./assets/plugins/resetMessage";
Vue.use(ElementUI);
Vue.prototype.$message = myMessage;
Vue.use(VueClipboard); //复制
Vue.use(Print); //打印

// 引入自定义js文件
import "@/assets";
Vue.prototype.fileBaseUrl = "https://ow365.cn/?i=33679&ssl=1&furl="; //预览文件前缀：附件路径https://开头的使用
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
