// 会员中心,所有name前缀带v
export default {
  parent: "member",
  routers: [
    // 邀请奖励——佣金奖励明细
    {
      path: "invite/brokerage",
      name: "vinviteBrokerage",
      component: () => import("@/views/member/inviteYjjl.vue"),
    },
    // 邀请奖励——团队人员记录
    {
      path: "invite/team",
      name: "vinviteTeam",
      component: () => import("@/views/member/inviteTeam.vue"),
    },
    // 邀请奖励——团队人员详情
    {
      path: "invite/team/detail",
      name: "vinviteTeamDetail",
      component: () => import("@/views/member/inviteTeamDetail.vue"),
    },
  ],
};
