// import store from "@/store";
// import Vue from "vue";
// import html2canvas from "html2canvas";

// 使用方法：this.$utils.方法名()
import config from "./config"
export default {
  apiUpload: config.baseUrl + "/pc/sys/common/upload",

  isTel(str) {
    return /^1(?:3\d|4[4-9]|5[0-35-9]|6[67]|7[013-8]|8\d|9\d)\d{8}$/.test(str);
  },
  // =============================================================下面是以前的代码，逐渐开发，逐渐删除
  //宽度自适应,value为选中文字
  autoWidth(value) {
    if (value == "" || value == 0) {
      return "85px";
    } else {
      return String(value).length * 12 + 15 + "px";
    }
  },
  // 文件下载
  download(url) {
    let a = document.createElement("a");
    a.href = url;
    a.download = true;
    a.click();
  },
  // 文件下载，支持txt/xml等格式的下载，可改名，暂时存在跨域问题
  downFn(url, name) {
    // let uu = url.replace(/\+/g, "%2B"); //encodeURI(url)
    fetch(url).then((res) => {
      res.blob().then((myBlob) => {
        const href = URL.createObjectURL(myBlob);
        const a = document.createElement("a");
        a.href = href;
        a.download = name; // 下载文件重命名
        a.click();
        a.remove();
      });
    });
  },
  downloadFile(url, name, type, method) {
    // url = url.replace(/\+/g, "%2B"); //encodeURI(url)
    var xhr = new XMLHttpRequest();
    xhr.open(method ? method : "GET", url, true);
    //xhr.setRequestHeader("Content-Type","application/json");
    xhr.responseType = "blob"; // 返回类型blob
    // 定义请求完成的处理函数，请求前也可以增加加载框/禁用下载按钮逻辑
    xhr.onload = function (res) {
      // 请求完成
      if (this.status === 200) {
        // 返回200
        var blob = this.response;
        var href = window.URL.createObjectURL(blob); //创建下载的链接
        console.log(res, blob, href, "导出");
        if (blob.type == "application/json" || blob.size == 0) {
          // Message({
          //   message: "导出失败",
          //   type: "error",
          // });
          return;
        }
        var date = new Date(); //实例一个时间对象；
        var y = date.getFullYear(); //获取系统的年；
        var M =
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1; //获取系统月份，由于月份是从0开始计算，所以要加1
        var d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate(); //获取系统日
        var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours(); //获取系统时间
        var mm =
          date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes(); //分
        var ss =
          date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds(); //秒
        name = name + "_" + y + M + d + h + mm + ss;

        //判断是否是IE浏览器，是的话返回true
        if (window.navigator.msSaveBlob) {
          try {
            window.navigator.msSaveBlob(blob, name);
          } catch (e) {
            console.log(e, "eee");
          }
        } else {
          // 谷歌浏览器 创建a标签 添加download属性下载
          var downloadElement = document.createElement("a");
          downloadElement.href = href;
          downloadElement.target = "_blank";
          downloadElement.download = name + type; //下载后文件名
          document.body.appendChild(downloadElement);
          downloadElement.click(); //点击下载
          document.body.removeChild(downloadElement); //下载完成移除元素
          window.URL.revokeObjectURL(href); //释放掉blob对象
        }
      } else {
        if (this.status == 404) {
          // Message({ message: "导出失败,文件未找到", type: "error" });
        } else {
          // Message({ message: "导出失败", type: "error" });
        }
        return;
      }
    };
    // 发送ajax请求
    xhr.send();
  },
  // 将数据转为树状结构
  getTreeData(data, options, callback) {
    let props = {
      id: "id",
      parentId: "parentId",
      children: "children",
    };
    Object.assign(props, options);
    let temp = {};
    let result = [];
    for (let item of data) {
      temp[item[props.id]] = item;
      if (callback) callback(item);
    }
    for (let item of data) {
      let parentId = item[props.parentId];
      let parent = temp[parentId];
      if (!parent) {
        result.push(item);
      } else {
        (parent[props.children] || (parent[props.children] = [])).push(item);
      }
    }
    return result;
  },
  // 密码安全级别检测
  checkPwd(password) {
    let result = 0;
    let arr = new Array(0, 0, 0, 0);
    for (let i = 0; i < password.length; i++) {
      let asciiNumber = password.substr(i, 1).charCodeAt();
      if (asciiNumber >= 48 && asciiNumber <= 57) {
        arr[0] = 1; //digital
      } else if (asciiNumber >= 97 && asciiNumber <= 122) {
        arr[1] = 1; //lowercase
      } else if (asciiNumber >= 65 && asciiNumber <= 90) {
        arr[2] = 1; //uppercase
      } else {
        arr[3] = 1; //specialcase
      }
    }
    for (let i = 0; i < arr.length; i++) {
      result += arr[i];
    }
    switch (result - 1) {
      case 2:
        return "中";
      case 3:
        return "高";
      default:
        return "低";
    }
  },
  // 遍历树状结构的数据
  eachTree(arr, callback, option = {}) {
    option.layer = option.layer || 1;
    for (let item of arr) {
      let args = { ...option };
      let children = item[option.children || "children"];
      if (children) {
        args.isLeaves = false;
        let res = callback(item, args);
        args.layer += 1;
        this.eachTree(children, callback, Object.assign({}, args, res));
      } else {
        args.isLeaves = true;
        callback(item, args);
      }
    }
  },
  dataURLtoFile(dataUrl, fileName) {
    var arr = dataUrl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
  },
};
