import Vue from "vue";
import store from "@/store";
import router from "@/router";
import config from "../utils/config"
const Axios = require("axios");
const axios = Axios.create({
  baseURL: config.baseUrl
});

// 添加请求拦截器
axios.interceptors.request.use(
  function (config) {
    // 添加请求头
    config.headers["X-Access-Token"] = store.state.user.access_token;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axios.interceptors.response.use(
  function (res) {
    let data = res.data;
    if (data.code != 200) {
      // 没有token或者token过期自动跳转到登录界面
      if (data.code == 401 || data.code == 406) {
        store.dispatch("user/setAccess_token", "");
        return router.replace({ name: "login" });
      }
      if (data.code != 500) {
        Vue.prototype.$message.error(data.message || "接口报错" + data.code);
        throw data.message || "接口报错" + data.code;
      }
    }
    return data;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// 上传文件的方法
axios.upload = function (url, data, options = {}) {
  let formData = new FormData();
  for (let k of Object.keys(data)) {
    let val = data[k];
    if (val instanceof Array) {
      for (let item of val) {
        formData.append(k, item);
      }
    } else {
      formData.append(k, val);
    }
  }
  return axios.post(url, formData, {
    headers: {
      ...(options.header || {}),
      "Content-Type": "multipart/form-data",
    },
    params: options.params,
  });
};

// /* 路由拦截： lenovoid 交互 */
// router.beforeEach(async (to, from, next) => {
//   let url = window.location.href.split("#")[0];
//   axios.post(`wxconfig`, { url }).then(({ data }) => {
//     wxConfig(data.data);
//   });
//   next();
// });

export default {
  install(Vue) {
    // 引入全局api
    const api = {};
    const req = require.context("@/api", true, /\.js$/);
    req.keys().forEach((path) => {
      let fn = req(path)?.default;
      if (typeof fn === "function") {
        Object.assign(api, fn(axios));
      }
    });
    Vue.prototype.$api = api;
    Vue.prototype.$axios = axios;
  },
};
