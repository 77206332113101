import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [];

// 引入路由
let map = {};
let req = require.context("./modules", true, /\.js$/);
req.keys().forEach((path) => {
  let routerObj = req(path)?.default;
  if (routerObj) {
    routerObj.routers.forEach((item) => {
      if (routerObj.parent) item["parent"] = routerObj.parent;
      map[item["name"]] = item;
    });
  }
});

// 将平铺结构整理为树状结构(多级嵌套)
Object.values(map).forEach((item) => {
  if (item.parent) {
    let p = map[item.parent];
    (p.children || (p.children = [])).push(item);
  } else {
    routes.push(item);
  }
});

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

//push
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

//replace
const VueRouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (to) {
  return VueRouterReplace.call(this, to).catch(err => err)
}


// const aaa = new Vuex({
//   ...mapGetters("user", ["config"]),
// });

// 这里是前置路由守卫
// router.beforeEach((to, from, next) => {
// });
// 这里是后置路由守卫
// router.afterEach((to, from) => {
// });

export default router;
