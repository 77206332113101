<template>
  <div id="app">
    <!-- <keep-alive> -->
    <router-view />
    <!-- </keep-alive> -->
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {};
  },
  created() {
    this.getBasicDetail();
  },
  methods: {
    ...mapActions("user", ["configFn"]),
    getBasicDetail() {
      let contact = [
        "OCS",
        "SPE",
        "VXS",
        "ADS",
        "IRN",
        "ABS",
        "JOIN",
        "ORDERPROTOCOL",
        "MINIFENMONEY",
      ];
      contact.forEach((e, i) => {
        this.$api.queryConfig({ code: e }).then((res) => {
          if (res.code == "200") {
            this.configFn({
              config: { field: e, value: res.result.content },
            });
            if (i == contact.length - 1) {
              this.configFn({
                config: { field: "isLoadingEnd", value: true },
              });
            }
          }
        });
      });
    },
  },
};
</script>

<style lang="less">
#app {
  width: 100%;
}
</style>
