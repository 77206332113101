export default {
  namespaced: true,
  local: true,
  state: {
    config: {},
    access_token: "",
    user: {},
  },
  mutations: {
    configFn(state, info) {
      if (info.config) {
        state.config[info.config.field] = info.config.value;
        // state.config = info.config;
      }
    },
    login(state, info) {
      if (info.access_token) state.access_token = info.access_token;
      if (info.user) state.user = info.user;
    },
    logout(state) {
      state.access_token = "";
      state.user = {};
    },
  },
};
