export default {
  routers: [
    // 首页
    {
      path: "/",
      name: "index",
      component: () => import("@/views/home/homeView.vue"),
    },
    // 关于我们
    {
      path: "/about",
      name: "about",
      component: () => import("@/views/home/aboutView.vue"),
    },
    // 业务介绍
    {
      path: "/business",
      name: "business",
      component: () => import("@/views/home/aboutView.vue"),
    },
    // 招商加盟
    {
      path: "/join",
      name: "join",
      component: () => import("@/views/home/aboutView.vue"),
    },
    // search搜索
    {
      path: "/search",
      name: "search",
      component: () => import("@/views/home/searchView.vue"),
    },
    // 公司产品
    {
      path: "/product",
      name: "product",
      component: () => import("@/views/home/productView.vue"),
    },
    // 公司产品>详情页
    {
      path: "/product/info",
      name: "productInfo",
      component: () => import("@/views/home/productInfo.vue"),
    },
    // 在线预算
    {
      path: "/budget",
      name: "budget",
      component: () => import("@/views/home/budgetView.vue"),
    },

    // 我的订单-汇总表
    {
      path: "/budget/hzb",
      name: "budgetHzb",
      component: () => import("@/views/home/orderHzb.vue"),
    },
    // 我的订单-报价单
    {
      path: "/budget/bjd",
      name: "budgetBjd",
      component: () => import("@/views/home/orderBjd.vue"),
    },
    // 我的订单-合同单
    {
      path: "/budget/htd",
      name: "budgetHtd",
      component: () => import("@/views/home/orderHtd.vue"),
    },
    // 我的订单-合同单-详情页
    {
      path: "/budget/htd/info",
      name: "budgetHtdInfo",
      component: () => import("@/views/home/orderHtdInfo.vue"),
    },
    // 我的订单-出货单
    {
      path: "/budget/chd",
      name: "budgetChd",
      component: () => import("@/views/home/orderChd.vue"),
    },
    // 我的订单-出货单-详情页
    {
      path: "/budget/chd/info",
      name: "budgetChdInfo",
      component: () => import("@/views/home/orderChdInfo.vue"),
    },

    // 在线预算-另存为后成功页面
    {
      path: "/budget/saveas",
      name: "budgetSaveAs",
      component: () => import("@/views/home/budgetSaveAs.vue"),
    },
    // 在线预算-立即下单-须知与协议
    {
      path: "/order/step/agree",
      name: "budgetOrderStep1",
      component: () => import("@/views/home/budgetOrderStep1.vue"),
    },
    // 在线预算-立即下单-支付
    {
      path: "/order/step/pay",
      name: "budgetOrderStep2",
      component: () => import("@/views/home/budgetOrderStep2.vue"),
    },
    // 在线预算-立即下单-分期
    {
      path: "/order/step/pay/installment",
      name: "budgetOrderStep3",
      component: () => import("@/views/home/budgetOrderStep3.vue"),
    },
    // 在线预算-立即下单-支付成功
    {
      path: "/order/step/pay/success",
      name: "budgetOrderStep4",
      component: () => import("@/views/home/budgetOrderStep4.vue"),
    },

    // 联系我们
    {
      path: "/contact",
      name: "contact",
      component: () => import("@/views/home/contactView.vue"),
    },
    // 我的订单
    {
      path: "/order",
      name: "order",
      component: () => import("@/views/home/orderView.vue"),
    },
    // 我的订单-预算明细
    {
      path: "/order/detail",
      name: "orderDetail",
      component: () => import("@/views/home/orderDetail.vue"),
    },
    // 我的订单-汇总表
    {
      path: "/order/hzb",
      name: "orderHzb",
      component: () => import("@/views/home/orderHzb.vue"),
    },
    // 我的订单-报价单
    {
      path: "/order/bjd",
      name: "orderBjd",
      component: () => import("@/views/home/orderBjd.vue"),
    },
    // 我的订单-转发分享
    {
      path: "/order/bjd/share",
      name: "orderBjdShare",
      component: () => import("@/views/home/orderBjdShare.vue"),
    },
    // 我的订单-合同单
    {
      path: "/order/htd",
      name: "orderHtd",
      component: () => import("@/views/home/orderHtd.vue"),
    },
    // 我的订单-合同单-详情页
    {
      path: "/order/htd/info",
      name: "orderHtdInfo",
      component: () => import("@/views/home/orderHtdInfo.vue"),
    },
    // 我的订单-出货单
    {
      path: "/order/chd",
      name: "orderChd",
      component: () => import("@/views/home/orderChd.vue"),
    },
    // 我的订单-出货单-详情页
    {
      path: "/order/chd/info",
      name: "orderChdInfo",
      component: () => import("@/views/home/orderChdInfo.vue"),
    },
    // 我的订单-出货单-出货汇总页
    {
      path: "/order/chd/summary",
      name: "orderChdSummary",
      component: () => import("@/views/home/orderChdSummary.vue"),
    },
    // 登录
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/home/loginView.vue"),
    },
    // 注册
    {
      path: "/reg",
      name: "reg",
      component: () => import("@/views/home/regView.vue"),
    },
    // 注册>完善资料
    {
      path: "/reg2",
      name: "reg2",
      component: () => import("@/views/home/reg2View.vue"),
    },
    // 注册>用户协议
    {
      path: "/agree",
      name: "agree",
      component: () => import("@/views/home/regAgreeView.vue"),
    },

    // 忘记密码
    {
      path: "/forget",
      name: "forget",
      component: () => import("@/views/home/forgetView.vue"),
    },
    // 会员中心
    {
      path: "/member",
      name: "member",
      component: () => import("@/views/manage/ManageView.vue"),
    },
    // 404
    {
      path: "*",
      name: "404",
      component: () => import("@/views/error/NotFound.vue"),
    },
  ],
};
